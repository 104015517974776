<template>
  <van-popup
    class="popup"
    v-model="show"
    position="bottom"
    get-container="body"
    round
    safe-area-inset-bottom
    @close="onClose"
  >
    <div class="popup">
      <div class="header-title">选择体检日期</div>
      <div class="week-header">
        <div class="week-header-item" v-for="item in week" :key="item">
          {{ item }}
        </div>
      </div>

      <div class="calendar" ref="calendar">
        <div
          v-for="(month, index) in result"
          :key="index"
          :id="`date${dayjs(month[10].date).format('YYYY-MM')}`"
        >
          <div class="month-title">
            {{ dayjs(month[10].date).format("YYYY年MM月") }}
          </div>
          <div class="date-container">
            <span
              :id="item.date ? `date${item.date}` : null"
              class="date"
              v-for="(item, week) in month"
              :key="item.date"
              @click="onDateClick(item)"
            >
              <span
                class="date-day"
                :class="[item.date === checkDay ? 'date-selected' : '']"
                :style="{
                  color: item.leftNum > 0 ? '#2C2F37' : '#ccc',
                }"
                >{{ item.date ? dayjs(item.date).format("DD") : "" }}</span
              >
              <span class="date-status">{{
                item.leftNum > 0 ? "可约" : ""
              }}</span>
            </span>
          </div>
        </div>
      </div>

      <div class="period-container" v-if="period && period.length">
        <span
          :class="[
            periodTime === item.time ? 'period-item-active' : 'period-item',
          ]"
          v-for="(item, index) in period"
          :key="index"
          @click="onPeriodClick(item)"
          >{{ item.time }}</span
        >
      </div>

      <div class="confirm">
        <div class="confirm-button" @click="onConfirmDate">确认预约</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import dayjs from "dayjs";
import { GetProductSchedules } from "@/api";

export default {
  props: {
    showPopup: {
      type: Boolean,
    },
    hospitalProductId: {
      type: String | Number,
    },
    defaultDate: {
      type: Object,
      default: () => {
        return {
          date: "", //日期
          time: "", //预约时间
          period: [], //排期表
        };
      },
    },
  },
  data() {
    return {
      show: false,
      days: [],
      week: ["一", "二", "三", "四", "五", "六", "日"],
      result: null,
      checkDay: "",
      beginDate: dayjs().startOf("month").format("YYYY-MM-DD"),
      endDate: dayjs()
        .subtract(-2, "months")
        .endOf("month")
        .format("YYYY-MM-DD"),
      period: [],
      periodTime: "",
      dateOffsetTop: [],
    };
  },
  watch: {
    showPopup(value) {
      this.show = value;
      const defaultDate = this.defaultDate;
      if (value && defaultDate && defaultDate.date) {
        this.checkDay = defaultDate.date;
        this.periodTime = defaultDate.time;
        this.period = defaultDate.period;
      }
      if (value) {
        this.getMonthRect();

        this.scrollIntoView(defaultDate);
      }
    },
  },
  mounted() {
    this.getSchedules();
  },
  methods: {
    dayjs,
    async getSchedules() {
      const params = {
        HospitalProductId: this.$props.hospitalProductId,
        beginDate: this.beginDate,
        endDate: this.endDate,
      };
      let data = await GetProductSchedules(params);
      this.days = data;
      this.result = this.getDate(this.days);
    },
    getDate(data) {
      let groupedData = [];
      data.forEach((item) => {
        const month = dayjs(item.date).format("MM");
        const year = dayjs(item.date).format("YY");
        const key = year + month;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(item);
      });
      groupedData = groupedData.filter((item) => item);
      //计算填充数量
      groupedData.forEach((month, index) => {
        const firstDay = month[0].date; //本月的第一天
        const lastDay = month[month.length - 1].date; //当月的最后一天
        //头部需要填充的格子数,周日是dayjs(firstDay).day()为0,需要填充6格
        const firstDayFill = dayjs(firstDay).day()
          ? dayjs(firstDay).day() - 1
          : 6;
        //周日时dayjs(lastDay).day()为0,不需要填充
        const lastDayFill = dayjs(lastDay).day() ? 7 - dayjs(lastDay).day() : 0; //尾部需要填充的格子数
        for (let i = 0; i < firstDayFill; i++) {
          month.unshift({});
        }
        for (let i = 0; i < lastDayFill; i++) {
          month.push({});
        }
      });
      return groupedData;
    },
    getMonthRect() {
      this.$nextTick(() => {
        const dateList = [
          dayjs().format("YYYY-MM"),
          dayjs().subtract(-1, "months").format("YYYY-MM"),
          dayjs().subtract(-2, "months").format("YYYY-MM"),
        ];
        this.dateOffsetTop = dateList.map((month) => {
          const query = document.querySelector(`#date${month}`);
          return {
            date: month,
            offsetTop: query.offsetHeight + query.offsetTop,
          };
        });
      });
    },
    //日期点击
    onDateClick(item) {
      if (item.leftNum > 0) {
        if (item.date !== this.checkDay) {
          this.periodTime = "";
        }
        this.checkDay = item.date;
        this.period = item.period || [];
        this.periodTime = this.period.find((item) => item.left > 0)?.time;
      }
    },
    //时间段点击
    onPeriodClick(item) {
      if (item.left > 0) {
        this.periodTime = item.time;
      } else {
        this.$toast("该时间段已经没有号源了哦~");
      }
    },
    //确定预约
    onConfirmDate() {
      if (!this.checkDay || !this.periodTime) {
        return this.$toast("请先选择预约时间哦~");
      }
      const appointmentTime = {
        date: this.checkDay,
        time: this.periodTime,
        period: this.period,
      };
      console.log(appointmentTime);
      this.$emit("onConfirm", appointmentTime);
      this.$store.commit("setAppointmentTime", appointmentTime);
      this.onClose();
    },
    onClose() {
      this.$emit("onClosePopup");
    },
    scrollIntoView(defaultDate) {
      const date = defaultDate.date;
      if (date) {
        this.$nextTick(() => {
          const monthOffsetTop = document.querySelector(
            `#date${date}`
          ).offsetTop;
          document.querySelector(".calendar").scrollTo({
            top: monthOffsetTop,
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.popup {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.header-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.icon-close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 15px 20px;
  width: 15px;
  height: 15px;
}

.week-header {
  display: flex;
  align-items: center;
  padding: 0 1%;
  height: 38px;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.week-header-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14%;
}

.calendar {
  position: relative;
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 10px;
}

.month-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  line-height: 100%;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.month-operation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 34px;
}

.date-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1%;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 14%;
}

.date-day {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  font-size: 16px;
  font-weight: bold;
  color: #cccccc;
  line-height: 23px;
}

.date-status {
  margin-top: 3px;
  font-size: 12px;
  height: 17px;
  font-weight: 400;
  color: #00c4b3;
  line-height: 14px;
}

.confirm {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  width: 100%;
  border-top: 1px solid #e0e0e0;
}

.confirm-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #00c4b3;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.date-selected {
  background: #00c4b3;
  border-radius: 10px;
  color: #fff !important;
}

.period-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #f4f4f4;
}

.period-item,
.period-item-active {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  flex: 1;
  background: #fff;
  border-radius: 10px;
  font-size: 16px;
  margin-left: 10px;
  font-weight: bold;
  box-sizing: border-box;
}

.period-item:first-child,
.period-item-active:first-child {
  margin-left: 0;
}
.period-item-active {
  color: #00c4b3;
  background: rgba(0, 196, 179, 0.1);
  border: 1px solid #00c4b3;
}
</style>
