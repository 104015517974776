<template>
  <div class="container">
    <div class="sex-key" v-if="productInfo.dictSexKey === '男'">限男性</div>
    <div class="sex-key" v-else-if="productInfo.dictSexKey === '女'">
      限女性
    </div>
    <div class="sex-key" v-else>男女通用</div>
    <div class="product-name">{{ productInfo.productName }}</div>
    <div class="tags">
      <span class="tag-item">{{ getProjectItemCount }}个检查项目</span>
      <span
        class="tag-item"
        v-if="
          productInfo.projectDiseaseItems &&
          productInfo.projectDiseaseItems.length
        "
        >筛查{{ productInfo.projectDiseaseItems.length }}个疾病</span
      >
    </div>
    <div class="price">
      <div class="sale-price">
        <span class="font14 bold">￥</span
        ><span class="bold">{{ productInfo.salePrice }}</span>
      </div>
      <span class="appointment-count">已约{{ productInfo.salesVolume }}</span>
    </div>
    <div class="promise">
      <img class="icon-promise" src="@/assets/icon-promise.png" alt="" />
      <div class="promise-right">
        <span class="promise-text">平台保障</span>
        <span class="promise-text">在线预约</span>
        <span class="promise-text">支持退改</span>
        <span class="promise-text">全年有效</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getProjectItemCount() {
      if (this.$props.productInfo.portfolioItems) {
        let count = 0;
        this.$props.productInfo.portfolioItems.forEach((item) => {
          count += item.items.length;

        });
        return count;
      }
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  padding: 24px 10px 16px 10px;
  background-color: #fff;
  border-radius: 10px;
}

.sex-key {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background: linear-gradient(220deg, #ee7c3c 3%, #ff3d00 100%);
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  height: 20px;
  color: #fff;
}

.product-name {
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
  line-height: 20px;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
}

.tag-item {
  display: flex;
  align-items: center;
  height: 18px;
  padding: 0 4px;
  margin-top: 5px;
  margin-right: 5px;
  color: #ffb470;
  background: rgba(255, 185, 16, 0.1);
  border-radius: 4px;
}
/* .tag-item:first-child {
  margin-right: 0;
} */

.tag-item:nth-child(-n + 2) {
  color: #0091ff;
  background: rgba(60, 196, 255, 0.1);
}

.price {
  display: flex;
  align-items: baseline;
  margin-top: 6px;
}

.sale-price {
  font-size: 20px;
  color: #ff3d00;
  font-weight: bold;
}

.appointment-count {
  margin-left: 10px;
  font-size: 13px;
  color: #878d99;
}

.promise {
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 0 25px 0 10px;
  height: 40px;
  background: rgba(0, 196, 179, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(0, 196, 179, 0.3);
}

.icon-promise {
  width: 17px;
  height: 17px;
}

.promise-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 3px;
}

.promise-text {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #00c4b3;
}

/* .promise-text:first-child {
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  font-size: 16px;
} */
</style>