<template>
  <div style="height: 60px">
    <div class="footer-container">
      <span class="price" style="font-size: 20px">
        <span class="bold font14">￥</span>
        <span class="bold font20">{{ price }}</span>
      </span>
      <div class="button" @click="buyClick">立即预约</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: String | Number,
      default: 0,
    },
  },
  methods:{
    buyClick(){
        this.$emit('buyClick')
    }
  }
};
</script>

<style scoped>
.footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  padding: 0 10px;
  background-color: #fff;
  z-index: 9;
}

.price {
  font-size: 14px;
  font-weight: bold;
  color: #ff3d00;
}

.button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  height: 40px;
  background: #00c4b3;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
}
</style>