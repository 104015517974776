<template>
  <div class="container">
    <div class="header">
      <div class="header-border"></div>
      体检须知
    </div>

    <div
      ref="main"
      class="main"
      :style="{ height: showHeight ? '220px' : 'unset' }"
    >
      <div class="conetnt-item">
        {{ notice }}
      </div>
    </div>

    <div v-if="showHeight" class="read-more" @click="showHeight = false">
      <img class="arrow-down" src="@/assets/icon-arrow-down.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notice: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showHeight: false,
    };
  },
  mounted() {
    if (this.$refs.main && this.$refs.main.offsetHeight > 220) {
      this.showHeight = true;
    } else {
      this.showHeight = false;
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  margin-top: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: 53px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
}
.header-border {
  position: absolute;
  left: -5px;
  top: 19px;
  width: 10px;
  height: 15px;
  background: rgba(0, 196, 179, 0.5);
  border-radius: 10px;
}

.main {
  position: relative;
  padding: 0 10px;
  overflow: hidden;
}

.conetnt-item {
  display: flex;
  margin-bottom: 10px;
  white-space: pre-wrap;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
  line-height: 20px;
}

.read-more {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 55px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
}

.arrow-down {
  width: 14px;
  height: 13px;
}
</style>