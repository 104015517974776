<template>
  <div class="project-info">
    <div class="content-header">
      <div class="flex">
        <div class="left-arrow"></div>
        <div class="left-arrow"></div>
        <div class="left-arrow"></div>
      </div>
      <span class="content-title">套餐项</span>
      <div class="flex">
        <div class="right-arrow"></div>
        <div class="right-arrow"></div>
        <div class="right-arrow"></div>
      </div>
    </div>

    <div class="project-th">
      <span class="project-th-left">项目</span>
      <span class="project-th-right">项目描述</span>
    </div>

    <div class="item" v-for="(item, index) in projectInfo" :key="index">
      <div class="project-title">{{ item.name }}</div>
      <div class="flex" v-for="(list, index) in item.items" :key="index">
        <div class="project-name">{{ list.name }}</div>
        <div class="project-desc">
          {{ list.description || "暂时还没有描述哦~" }}
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    projectInfo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
  
  <style scoped>
.project-info {
  padding: 20px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fff;
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.left-arrow {
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-right: 6px solid #ffb910;
}

.right-arrow {
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-left: 6px solid #ffb910;
}

.content-title {
  margin: 0 8px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
  line-height: 19px;
}

.project-th {
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  background: #00c4b3;
  border: 1px solid #edeff0;
  border-radius: 10px;
}

.project-th-left,
.project-th-right {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  font-size: 15px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #fff;
}

.project-th-left {
  width: 72px;
  border-right: 1px solid #edeff0;
}
.project-th-right {
  flex: 1;
}

.item {
  border-radius: 10px;
  border: 1px solid #edeff0;
  overflow: hidden;
}

.item:not(:last-child) {
  margin-bottom: 10px;

}

.item .flex:not(:last-child) {
  border-bottom: 1px solid #edeff0;
}

.project-title {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 100%;
  height: 36px;
  font-size: 15px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #00c4b3;
  font-weight: bold;
  background: rgba(0, 196, 179, 0.1);
}

.project-name,
.project-desc {
  padding: 8px;
  font-size: 13px;
  line-height: 20px;
  color: #878d99;
  font-family: PingFang SC-Bold, PingFang SC;
}

.project-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 72px;
  font-weight: bold;
  border-right: 1px solid #edeff0;
}
.project-desc {
  flex: 1;
}
</style>