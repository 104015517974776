<template>
  <div class="container">
    <div class="header" @click="showPopup = true">
      <div class="header-border"></div>
      机构信息
      <span class="look-more" @click="showPopup = true">
        查看详情
        <img class="icon-right" src="@/assets/icon-right-primary.png" alt="" />
      </span>
    </div>

    <div class="hospital-header">
      <img
        class="hospital-logo"
        :src="hospitalInfo.logoUrl | filterImg"
        alt=""
      />
      <div class="van-ellipsis" style="flex: 1; margin-top: 5px">
        <span class="hospital-name van-ellipsis">{{
          hospitalInfo.displayName
        }}</span>
        <div style="display: flex">
          <div
            class="hospital-category"
            v-if="
              hospitalInfo.showHospitalLevel &&
              hospitalInfo.dictCategoryKeyName &&
              hospitalInfo.dictCategoryKeyName.length
            "
          >
            {{ hospitalInfo.dictCategoryKeyName[0] }}
          </div>
          <div class="hospital-tag">
            {{ advanceDays }}
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="hospital-address" v-if="hospitalInfo.address">
        <div class="address-name van-ellipsis">
          <img class="icon-location" src="@/assets/icon-location.png" alt="" />
          <span class="van-ellipsis">{{hospitalInfo.areaName}}{{ hospitalInfo.address }}</span>
        </div>

        <div class="address-right">
          <div class="distance" @click="openLocation">
            <img
              class="icon-location-primary"
              src="@/assets/icon-location-primary.png"
              alt=""
            />
            <span>导航</span>
          </div>
          <div class="divider"></div>

          <div class="copy">
            <img
              class="icon-copy"
              src="@/assets/icon_copy.png"
              alt=""
              @click="copyAddress"
            />
            <span>复制</span>
          </div>
        </div>
      </div>
    </div>

    <hospitalPopup
      :showPopup="showPopup"
      :hospitalInfo="hospitalInfo"
      @onClosePopup="showPopup = false"
    ></hospitalPopup>
  </div>
</template>

<script>
import WxUtil from "@/utils/wxUtils.js";
import { isNull } from "@/utils/index.js";

import hospitalPopup from "@/components/hospitalPopup.vue";

export default {
  components: {
    hospitalPopup,
  },
  props: {
    hospitalInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showPopup: false,
    };
  },
  computed: {
    advanceDays() {
      if (isNull(this.$props.hospitalInfo.advanceDays)) return;
      const day = this.$props.hospitalInfo.advanceDays;
      if (day == 0) {
        return "当天可约";
      } else {
        return `提前${day}天预约`;
      }
    },
  },
  methods: {
    //打开地图
    openLocation() {
      const hospitalInfo = this.$props.hospitalInfo;
      const latitude = hospitalInfo.latAndLon.split(",")[1];
      const longitude = hospitalInfo.latAndLon.split(",")[0];
      WxUtil.lookLocation({
        latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
        longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
        name: hospitalInfo.name, // 位置名
        address: hospitalInfo.address, // 地址详情说明
        scale: 14, // 地图缩放级别,整形值,范围从1~28。默认为最大
        infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
      });
    },
    //复制地址
    copyAddress() {
      let that = this;
      this.$copyText(this.hospitalInfo.areaName + this.hospitalInfo.address).then(
        function (e) {
          that.$toast("已复制地址");
        },
        function (e) {
          that.$toast("复制失败请重试");
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #fff;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 53px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
}
.header-border {
  position: absolute;
  left: -5px;
  top: 19px;
  width: 10px;
  height: 15px;
  background: rgba(0, 196, 179, 0.5);
  border-radius: 10px;
}

.look-more {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #00c4b3;
  line-height: 14px;
}

.hospital-header {
  display: flex;
  padding: 0 10px 0 15px;
  background-color: #fff;
  border-radius: 10px;
}

.hospital-logo {
  margin-right: 10px;
  width: 54px;
  height: 54px;
  border-radius: 6px;
}

.hospital-name {
  font-size: 16px;
  font-weight: bold;
  color: #2c2f37;
  line-height: 20px;
}

.hospital-category,
.hospital-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 4px;
  font-size: 13px;
  font-weight: bold;
  background-color: #fff;
  color: #00c4b3;
  border: 1px solid rgba(49, 207, 150, 0.2);
  border-radius: 4px;
}

.footer {
  padding: 0 15px 10px 15px;
}

.hospital-address,
.hospital-worktime {
  display: flex;
  align-items: center;
  color: #00c4b3;
  font-size: 13px;
  justify-content: space-between;
}

.address-name,
.work-time {
  font-size: 13px;
  line-height: 14px;
  color: #475266;
}

.work-time {
  display: flex;
  flex: 1;
}

.distance {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #878d99;
  font-size: 12px;
}

.address {
  display: flex;
  align-items: center;
}

.address-name {
  display: flex;
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #475266;
  line-height: 14px;
}

.address-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.copy {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #878d99;
  font-size: 12px;
}

.icon-copy {
  margin-bottom: 2px;
  width: 14px;
  height: 14px;
}

.icon-time {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.icon-location {
  width: 11px;
  height: 13px;
  margin-right: 5px;
}

.icon-location-primary {
  margin-bottom: 2px;
  width: 14px;
  height: 14px;
}

.icon-right {
  margin-left: 2px;
  width: 8px;
  height: 8px;
}

.divider {
  margin: 0 10px;
  width: 1px;
  height: 20px;
  background: #edeff0;
}
</style>