<template>
  <div class="container">
    <div class="header" @click="showCalendar({})">
      <div>
        <span class="header-title">
          预约时间
          <div class="header-border"></div>
        </span>
        <span class="header-desc">最快可约{{ fastestTime }}</span>
      </div>
      <img class="icon-right" src="@/assets/icon-right-primary.png" alt="" />
    </div>

    <div style="padding-left: 10px">
      <ul ref="dateUl" class="date-ul">
        <li
          class="date-li"
          v-for="(item, index) in days"
          :key="index"
          @click="onDateClick(item)"
        >
          <div class="date-li-header">
            <span class="date-week-day">{{ item.weekOfDay }}</span>
            <span class="date-day">{{ dayjs(item.date).format("MM/DD") }}</span>
          </div>
          <div
            class="date-li-footer"
            :class="item.leftNum > 0 ? 'date-li-footer-active' : ''"
          >
            <template v-if="item.leftNum > 0">
              <div>点击</div>
              <div>预约</div>
            </template>
            <template v-else>
              <div>约满</div>
            </template>
          </div>
        </li>
      </ul>
    </div>

    <div class="date-tips">左右滑动日历查看其它预约时间</div>

    <div class="scroll-bar-container">
      <div :style="{ 'margin-left': marginLeft }" class="scroll-bar"></div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { GetProductSchedules } from "@/api";

export default {
  props: {
    productId: {
      type: Number | String,
    },
  },
  data() {
    return {
      days: [],
      marginLeft: "",
    };
  },
  computed: {
    fastestTime() {
      const day = this.days.find((item) => item.leftNum > 0)?.date;
      return dayjs(day).format("M月D日");
    },
  },
  mounted() {
    this.getSchedules();
    this.$nextTick(() => {
      this.$refs.dateUl &&
        this.$refs.dateUl.addEventListener("scroll", this.onScroll, true);
    });
  },
  methods: {
    dayjs,
    async getSchedules() {
      const params = {
        HospitalProductId: this.$props.productId,
        beginDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().add(1, "month").format("YYYY-MM-DD"),
      };
      let data = await GetProductSchedules(params);
      this.days = data;
    },
    onScroll() {
      const el = this.$refs.dateUl;
      const scrollLeft = el.scrollLeft;
      const scrollWidth = el.scrollWidth;
      const offsetWidth = el.offsetWidth;
      this.marginLeft = (scrollLeft / (scrollWidth - offsetWidth)) * 50 + "%";
    },
    //日期点击
    onDateClick(item) {
      console.log(item);
      if (item.leftNum > 0) {
        const time = item.period.find((item) => item.left > 0)?.time;
        this.showCalendar({
          date: item.date,
          period: item.period,
          time,
        });
      }
    },
    showCalendar(date = {}) {
      this.$emit("showCalendar", date);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  margin-top: 10px;
  padding-bottom: 15px;
  border-radius: 10px;
  background-color: #fff;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 53px;
}

.header-title {
  position: relative;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
  z-index: 2;
}

.header-desc {
  margin-left: 10px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #878d99;
}
.header-border {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.2;
  background: #08d794;
  border-radius: 0 100px 0 100px;
  height: 9px;
  width: 50px;
}

.icon-right {
  margin-left: 2px;
  width: 8px;
  height: 8px;
}

.date-ul {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.date-ul::-webkit-scrollbar {
  display: none;
}

.date-li {
  display: flex;
  flex-direction: column;
}
.date-li-header,
.date-li-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 48px;
  height: 48px;
  border: 1px solid #eff1f1;
}
.date-li-footer {
  // padding: 0 10px;
  text-align: center;
  border-top: none;
  background: #f4f4f4;
  color: #878d99;
  font-weight: bold;
}

.date-li-footer-active {
  background: #00c4b3;
  color: #ffffff;
  font-weight: bold;
  border: none;
}

.date-tips {
  margin-top: 10px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
  line-height: 14px;
  width: 100%;
  text-align: center;
}

.date-week-day {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
  line-height: 14px;
}

.date-day {
  font-size: 12px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 14px;
}

.scroll-bar-container {
  margin-top: 5px;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background: #d9d9d9;
  border-radius: 20px;
  overflow: hidden;
}

.scroll-bar {
  width: 50%;
  height: 100%;
  background-color: #00c4b3;
  border-radius: 20px;
}
</style>