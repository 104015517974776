<template>
  <div class="container">
    <div class="header">
      <div class="header-border"></div>
      关于退改
    </div>

    <div class="item" v-for="(item, index) in cancellationPolicy" :key="index">
      <div class="item-header">
        <img class="icon" src="@/assets/icon-question.png" alt="" />
        <span class="item-title">{{ item.name }}</span>
      </div>
      <span class="item-desc">{{ item.content }}</span>
    </div>

    <div class="call-server">
      <a class="call-btn" @click="callPhone">
        <img class="icon-phone" src="@/assets/icon-phone.png" alt="" />
        <span>拨打客服热线</span>
      </a>
      <div class="call-desc">客服在线时间：{{ workTime }}</div>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";

export default {
  props: {
    cancellationPolicy: {
      type: Array,
      default: () => [],
    },
    productHospitalId: {
      type: String,
    },
  },
  computed: {
    hospitalId() {
      return this.$store.state.hospitalId;
    },
  },
  watch: {
    hospitalId: {
      handler: function (val) {
        if (val === "12492") {
          this.message =
            "请拨打0631-3806868到医院体检中心人工咨询（上午8:00-11:30，下午1:30-5:30，法定节假日除外）";
          this.telNo = "0631-3806868";
          this.workTime = "上午8:00-11:30，下午1:30-5:30，法定节假日除外";
        } else {
          this.message =
            "请拨打4009003732转2到体检后转0到人工（周一到周日8:00-20:00)";
          this.telNo = "4009003732";
          this.workTime = "周一到周日8:00-20:00";
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      telNo: "",
      workTime: "",
      message: "",
    };
  },
  methods: {
    callPhone() {
      Dialog.confirm({
        message: this.message,
        confirmButtonColor: "#00c4b3",
      })
        .then(() => {
          // on confirm
          window.location.href = `tel:${this.telNo}`;
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  margin-top: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: 53px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
}
.header-border {
  position: absolute;
  left: -5px;
  top: 19px;
  width: 10px;
  height: 15px;
  background: rgba(0, 196, 179, 0.5);
  border-radius: 10px;
}

.conetnt-item {
  display: flex;
  padding: 0 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
  line-height: 20px;
}

.call-server {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.call-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 40px;
  border: 1px solid #00c4b3;
  border-radius: 20px;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #00c4b3;
}

.icon-phone {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.call-desc {
  margin: 10px 10px 0 10px;
  color: #ccc;
  font-size: 14px;
  text-align: center;
}

.item {
  margin: 0 10px 10px 10px;
  padding: 10px;
  background: rgba(244, 244, 244, 0.6);
  border-radius: 10px;
}
.item-header {
  display: flex;
  align-items: center;
}

.item-title {
  margin-left: 8px;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #475266;
  line-height: 16px;
}

.icon {
  width: 17px;
  height: 17px;
}

.item-desc {
  display: flex;
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #878d99;
  line-height: 24px;
  white-space: pre-wrap;
}
</style>
