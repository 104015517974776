<template>
  <div class="project-disease">
    <div
      style="
        height: 2px;
        width: 100%;
        position: absolute;
        top: -2px;
        left: 0;
        background-color: #fff;
      "
    ></div>
    <div class="content-header">
      <div class="flex">
        <div class="left-arrow"></div>
        <div class="left-arrow"></div>
        <div class="left-arrow"></div>
      </div>
      <span class="content-title"> 重点筛查 </span>
      <div class="flex">
        <div class="right-arrow"></div>
        <div class="right-arrow"></div>
        <div class="right-arrow"></div>
      </div>
    </div>
    <div ref="project" class="content-box">
      <div
        class="flex"
        :style="{
          width: `${projectDiseaseItems.length * 132}px`,
        }"
      >
        <div
          class="content-item"
          v-for="(item, index) in projectDiseaseItems"
          :key="index"
        >
          <div class="content-item-img">
            <img :src="baseUrl + item.urls[0]" alt="" />
          </div>
          <div class="content-name">
            {{ item.name }}
          </div>
          <div class="content-desc" v-if="item.description">
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="scroll-bar-container" v-if="haveScrollBar">
      <div :style="{ 'margin-left': marginLeft }" class="scroll-bar"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projectDiseaseItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_FILE_BASEURL,
      marginLeft: "",
      haveScrollBar: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const el = this.$refs.project;
      el && el.addEventListener("scroll", this.onScroll, true);
    });
  },
  updated() {
    const el = this.$refs.project;
    const clientWidth = el.clientWidth;
    const scrollWidth = el.scrollWidth;
    this.haveScrollBar = scrollWidth > clientWidth;
  },
  methods: {
    onScroll() {
      const el = this.$refs.project;
      const scrollLeft = el.scrollLeft;
      const scrollWidth = el.scrollWidth;
      const offsetWidth = el.offsetWidth;
      this.marginLeft = (scrollLeft / (scrollWidth - offsetWidth)) * 50 + "%";
    },
  },
};
</script>

<style scoped>
.project-disease {
  position: relative;
  margin-top: 2px;
  padding-top: 20px;
  background-color: #fff;
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.left-arrow {
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-right: 6px solid #ffb910;
}

.right-arrow {
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-left: 6px solid #ffb910;
}

.content-title {
  margin: 0 8px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
  line-height: 19px;
}

.content-box {
  padding-bottom: 20px;
  margin-top: 16px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.content-box::-webkit-scrollbar {
  display: none;
}

.content-item {
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 18px;
}

.content-name {
  margin-top: 6px;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
  line-height: 16px;
}

.content-item-img {
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.content-desc {
  margin-top: 7px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
  line-height: 14px;
  text-align: center;
}

.scroll-bar-container {
  margin-top: 5px;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background: #d9d9d9;
  border-radius: 20px;
}

.scroll-bar {
  width: 50%;
  height: 100%;
  background-color: #00c4b3;
  border-radius: 20px;
}
</style>